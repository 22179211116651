$(function(){

//INFORMATION スライダー
var infoSwiper = new Swiper ('.swiper-container-info', {
	slidesPerView: 'auto',
	speed: 100,
	spaceBetween: 16,
	loop: false,
  navigation: {
		nextEl: '.swiper-button-next-info',
		prevEl: '.swiper-button-prev-info',
	},
})


//feature_list スライダー
var featureSwiper = new Swiper ('.swiper-container-feature_list', {
	slidesPerView: 'auto',
	speed: 500,
	navigation: {
		nextEl: '.swiper-button-next-feature_list',
		prevEl: '.swiper-button-prev-feature_list',
	},
	pagination: {
	  el: '.swiper-pagination-feature_list',
	  type: 'bullets',
	  clickable: true,
	},
})

//home_product-hard スライダー
var homeProductHardSwiper = new Swiper ('.swiper-container-home_product-hard', {
	slidesPerView: 'auto',
	speed: 400,
	loop: true,
  autoplay: {
	  delay: 2500,
	 	disableOnInteraction: false,
	},
	navigation: {
		nextEl: '.swiper-button-next-home_product-hard',
		prevEl: '.swiper-button-prev-home_product-hard',
	},
	pagination: {
	  el: '.swiper-pagination-home_product-hard',
	  type: 'bullets',
	  clickable: true,
	},
})
$('.swiper-button-prev-home_product-hard').on('click',function(){
	homeProductHardSwiper.slideTo(homeProductHardSwiper.activeIndex-2);
});
$('.swiper-button-next-home_product-hard').on('click',function(){
	homeProductHardSwiper.slideTo(homeProductHardSwiper.activeIndex+2);
});

//home_product-hard スライダー
var homeProductDrinkSwiper = new Swiper ('.swiper-container-home_product-drink', {
	slidesPerView: 'auto',
	speed: 400,
	loop: true,
  autoplay: {
	  delay: 2500,
	 	disableOnInteraction: false,
	},
	navigation: {
		nextEl: '.swiper-button-next-home_product-drink',
		prevEl: '.swiper-button-prev-home_product-drink',
	},
	pagination: {
	  el: '.swiper-pagination-home_product-drink',
	  type: 'bullets',
	  clickable: true,
	},
})
$('.swiper-button-next-home_product-drink').on('click',function(){
	homeProductDrinkSwiper.slideTo(homeProductDrinkSwiper.activeIndex+2);
});
$('.swiper-button-prev-home_product-drink').on('click',function(){
	homeProductDrinkSwiper.slideTo(homeProductDrinkSwiper.activeIndex-2);
});
});

